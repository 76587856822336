var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-success",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Komisi didapatkan")])]), _c('h2', [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.totalPenjualan)))])])])])])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-danger",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Komisi Tertunda")])]), _c('h2', [_c('strong', [_vm._v("Rp 250.000")])])])])])], 1)], 1), _c('b-card', [_c('center', [_vm._v("Target Komisi Bulanan : "), _c('b', {
    staticClass: "text-success"
  }, [_vm._v("Rp.2000.000/bulan")])]), _c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('section', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push('/penarikan-komisi');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Penarikan ")], 1)], 1)]), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h2', {
    staticClass: "text-success text-right"
  }, [_vm._v(" Total: "), _c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalPenjualan)))])])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(no_invoice)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.no_invoice ? item.no_invoice : "-") + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.status == 1 ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("SELESAI")]) : item.status == 2 ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PROSES")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("DIBATALKAN")])];
      }
    }, {
      key: "cell(rincian)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.rincian[0] ? item.rincian[0].nama_barang : "-") + " ")];
      }
    }, {
      key: "cell(uang_masuk)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.uang_masuk)))])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/transaksi-penjualan/detail/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/transaksi-penjualan/edit/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }