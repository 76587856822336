<template>
  <main>
    <b-overlay :show="loading">
      <!-- <form-filter-export :type_export="['excel']" @onExport="exportSales">
      <template #default>
        <b-form-group v-if="!isSales" label="Sales " class="mr-2">
          <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group label="Konsumen (opsional)">
          <v-select :options="konsumenOptions" v-model="dataExport.konsumen_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
      </template>
    </form-filter-export> -->
      <b-row>
        <b-col sm="12" md="6" lg="6">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-success`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi didapatkan</small></strong>
                <h2>
                  <strong>{{ formatRupiah(totalPenjualan) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="6">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-danger`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi Tertunda</small></strong>
                <h2><strong>Rp 250.000</strong></h2>
              </section>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card>
        <center>Target Komisi Bulanan : <b class="text-success">Rp.2000.000/bulan</b></center>
        <b-row class="align-items-center">
          <b-col sm="12" md="6" class="my-1">
            <section class="d-flex align-items-center">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click.prevent="$router.push('/penarikan-komisi')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Penarikan
              </b-button>
            </section>
          </b-col>
          <b-col sm="12" md="6" class="d-flex justify-content-end">
            <h2 class="text-success text-right">
              Total: <strong>Rp {{ formatRupiah(totalPenjualan) }}</strong>
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              small
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(no_invoice)="{ item }">
                {{ item.no_invoice ? item.no_invoice : "-" }}
              </template>
              <template #cell(status)="{ item }">
                <b-badge v-if="item.status == 1" variant="success">SELESAI</b-badge>
                <b-badge v-else-if="item.status == 2" variant="warning">PROSES</b-badge>
                <b-badge v-else variant="danger">DIBATALKAN</b-badge>
              </template>
              <template #cell(rincian)="{ item }">
                {{ item.rincian[0] ? item.rincian[0].nama_barang : "-" }}
              </template>
              <template #cell(uang_masuk)="{ item }">
                <strong>Rp {{ formatRupiah(item.uang_masuk) }}</strong>
              </template>

              <template #cell(action)="{ item }">
                <b-button size="sm" variant="warning" @click.prevent="$router.push(`/transaksi-penjualan/detail/${item.id}`)">
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button v-if="item.status == 0 && allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0" size="sm" variant="outline-warning" @click.prevent="$router.push(`/transaksi-penjualan/edit/${item.id}`)">
                  <feather-icon icon="EditIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </main>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import { BTable, BCard, VBTooltip, BDropdown, BDropdownItem, BAvatar, BBadge, BModal, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox, BOverlay } from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "../mixin";

export default {
  mixins: [mixin],
  components: {
    ToastificationContent,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      form: {
        blok: null,
        gudang_id: null,
      },
      dataGudang: [],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      dataExport: {
        konsumen_id: null,
        sales_id: null,
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "rincian", label: "Barang" },
        { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "uang_masuk", label: "Uang Masuk" },
        { key: "status", label: "Status Transaksi" },
        { key: "action", label: "#" },
      ],
      items: [],
      swalOptions: {
        title: "Pindahkan Stok Retur ke Mobil?",
        text: `Stok Barang Retur akan kembali ke Stok Mobil Anda`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Retur Barang",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      },
      salesOptions: [],
      konsumenOptions: [],
      loading: false,
    };
  },
  computed: {
    totalPenjualan() {
      if (!this.items || this.items.length < 1) {
        return 0;
      }

      return this.items.reduce((total, item) => (total += parseInt(item.uang_masuk)), 0);
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    async exportSales({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir,
        type_export,
      };
      if (this.isSales && this.myGudang) params.sales_id = this.user && this.user.karyawan ? this.user.karyawan.id : null;
      if (this.dataExport.konsumen_id) params.konsumen_id = this.dataExport.konsumen_id;
      if (this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id;

      try {
        this.loading = true;
        const response = await this.printSales(params);
        this.loading = false;
        if (type_export == "pdf") {
          window.open(URL.createObjectURL(response));
        } else {
          await window.open(response);
          setTimeout(async () => {
            // delete selected file
            const arrFile = response.split("/");
            const filename = arrFile[arrFile.length - 1];

            await this.clearExportedFile(filename);
          }, 1000);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getKonsumen() {
      const params = {
        order: "desc",
      };
      if (this.isSales) {
        params.sales_id = this.user.id;
        params.status = 3; // disetujui
      } else {
        params.status = 3;
      }

      const konsumens = await this.$store.dispatch("ajuankonsumen/getData", params);
      this.konsumenOptions = konsumens.map((item) => ({ value: item.id, text: item.nama_owner }));
    },
    async getSales() {
      const levels = await this.$store.dispatch("level/getData", {
        search: "sales",
      });
      const salesLevel = levels.find((level) => this.getShortName(level.nama_level) == "sales");
      const params = {};
      if (salesLevel) params.level_id = salesLevel.id;

      const sales = await this.$store.dispatch("pengguna/getData", params);
      this.salesOptions = sales.map((item) => ({
        value: item.karyawan.id,
        text: item.karyawan.nama_lengkap,
      }));
    },
    async getData() {
      this.loading = true;
      let gudang = JSON.parse(localStorage.getItem("myGudang"));
      const params = gudang ? { order: "desc", gudang_id: gudang.id } : { order: "desc" };
      this.items = await this.$store.dispatch("penjualan/transaksiToko", params); // belum difilter per sales
      if (this.items.length > 0) {
        this.loading = false;
      }
      this.items.map((penjualan) => (penjualan.checked = false));
      this.totalRows = this.items.length;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.getKonsumen();
    if (this.isSales) {
      this.fields = [
        {
          key: "no",
          label: "No",
        },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "rincian", label: "Barang" },
        { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "uang_masuk", label: "Uang Masuk" },
        { key: "status", label: "Status Transaksi" },
        { key: "action", label: "#" },
      ];
    } else {
      this.getSales();
      this.fields = [
        {
          key: "no",
          label: "No",
        },
        // { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "uang_masuk", label: "Uang Masuk" },
        { key: "status", label: "Status " },
        { key: "action", label: "#" },
      ];
    }
    this.getData();
  },
};
</script>
